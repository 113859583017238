<template>
	<div>
		<div v-if="navindex == 9">
			<div class="Ctitle">业务管理</div>
			<div class="post">
				<div class="search flex2">
					<ul class="search-ul">
						<li>&nbsp;</li>
					</ul>
					<div class="flex">
						<div class="release" @click="goto('/talentService')">收费标准</div>
						<div class="release" @click="FPtableAllchange()">索要发票</div>
						<div class="release" v-if="ispaybycomiddata != 1" @click="serviceClick()">办理业务</div>
					</div>
				</div>
			</div>
			<div class="business">
				<!-- <div class="business-top">
					<div class="title"></div>
					<div class="botton-li">
						<div class="botton btn-b" @click="FPtableAllchange">索要发票</div>
						<div class="botton btn-a" @click="serviceClick()">
							业务办理
						</div>
					</div>
				</div> -->
				<div class="business-bottom">
					<template>
						<el-table :data="AlltableData" stripe style="width: 100%" height="540"
							header-row-class-name="headerrowclass" row-class-name="rowclass"
							@selection-change="handleSelectionChange">
							<el-table-column type="selection" :selectable="selectable">
							</el-table-column>
							<el-table-column prop="service_Name" label="业务名称">
								<template slot-scope="scope">
									{{ scope.row.service_Name}}
									<span class="red" v-if="!scope.row.isPay">
										<i class="el-icon-timer"></i>
										未缴费
									</span>
									<span class="green" v-else-if="utils.nowInDateBetwen(0, scope.row.dend) != 2">
										<i class="el-icon-timer"></i>
										招聘中
									</span>
									<span class="red" v-else>
										<i class="el-icon-timer"></i>
										已过期
									</span>
								</template>
							</el-table-column>
							<el-table-column prop="service_Cs" label="业务说明">
							</el-table-column>
							<el-table-column align="center" label="到期时间" width="150">
								<template slot-scope="scope">
									{{ scope.row.dend ? stringToDates(scope.row.dend) : "" }}到期
								</template>
							</el-table-column>
							<el-table-column align="center" label="费用" width="100">
								<template slot-scope="scope"> ￥{{ scope.row.fee }} </template>
							</el-table-column>
							<el-table-column align="center" fixed="right" label="操作" width="100">
								<template slot-scope="scope">
									<div class="business-btn" v-if="utils.nowInDateBetwen(0, scope.row.dend) != 2">
										<div @click="serviceClick(scope.row, true)" class="red business-t"
											v-if="!scope.row.isPay">
											缴费
										</div>
										<div @click="serviceClick(scope.row, false)" class="blue business-t"
											v-if="scope.row.isPay">
											续费
										</div>
										<!-- <div
	              class="blue business-t"
	              @click="FPtablechange(scope.row.id)"
	              v-if="!scope.row.isTicket && scope.row.isPay"
	            >
	              <img src="@/assets/img/fp.png" alt="" />索要发票
	            </div> -->
									</div>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</div>
			</div>
			<div class="page" v-if="AlltableData.length > 0 && total > 10">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
					:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 续办 -->

		<el-dialog :wrapperClosable="false" :title="ispays ? '缴费办理' : '业务续办'" size="25%" :visible.sync="Service"
			:direction="direction">
			<div class="postadd">
				<div class="ul flex1">
					<div class="left">业务类别:</div>
					<div class="right">
						<el-select class="labelselect" v-model="JFXLdata[0].data" @change="JFChange($event, 0)"
							placeholder="请选择">
							<el-option v-for="item in JFXLdata[0].list" :key="item.cName" :label="item.cName"
								:value="item.cCode">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="ul flex1">
					<div class="left"></div>
					<div class="right">
						<div class="Tips">{{JFXLdata[0].cCs!=""?"服务内容："+JFXLdata[0].cCs:"请选择业务类别"}}</div>
					</div>
				</div>
				<div class="box-dialog">
					<div class="box-header">选择服务时长:</div>
					<div class="box-body">
						<div :class="['labelselect', JFXLdata[1].data == item.cName ? 'itemClick' : '']"
							v-for="item in JFXLdata[1].list" :key="item.cName"
							@click="JFXLdata[0].data!=''?JFChange(item, 1):JFChange1()">
							{{item.cName}}
						</div>
					</div>
				</div>
				<div class="ul" style="display: none;">
					<div class="left">业务起止日期：</div>
					<div class="right">
						<el-date-picker v-model="JFdate" type="daterange" :picker-options="pickerOptions"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							:disabled="ispays ? true : false">
						</el-date-picker>
					</div>
				</div>
				<div class="box-dialog flex">
					<div class="box-right">
						<p>支付方式：
							<el-radio-group v-model="JFdata.payment">
								<el-radio :label="'huikuan'">线下支付</el-radio>
								<el-radio :label="'alipay'" disabled>支付宝</el-radio>
								<el-radio :label="'wxpay'" disabled>微信</el-radio>
							</el-radio-group>
						</p>
						<p>户 名：<span>临海市人才市场</span></p>
						<p>开 户 行：<span>中国工商银行 临海市支行</span></p>
						<p>银行卡号：<span>1207 0211 0904 9066 996</span></p>
						<p>应付金额：<b style="color:#0089FF;">{{JFdata.FEE}}</b>元</p>
						<p style="align-items: end;">上传凭证：
							<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
								:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
								:on-success="uploadJFSuccess" :on-error="uploadimageerror">
								<img v-if="JFdata.payVucher" fit="contain" :src="defimgurl + JFdata.payVucher"
									class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</p>
					</div>
				</div>
				<div class="btn">
					<div class="preservation" @click="ContinuationService">
						{{ ispays ? "确认缴费" : "提交办理" }}
					</div>
					<div class="cancel" @click="Service = false,JFXLdata[0].cCs=''">取消</div>
				</div>
			</div>
		</el-dialog>
		<!-- 发票 -->
		<el-drawer title="索要发票" :wrapperClosable="false" size="25%" :visible.sync="FPshow" :direction="direction">
			<div class="postadd">
				<div class="ul">
					<div class="left">开票抬头:</div>
					<div class="right">
						<input type="text" v-model="FPData.companyName" placeholder="请输入" />
					</div>
				</div>

				<div class="ul">
					<div class="left">抬头类型：</div>
					<div class="right">
						<input type="text" v-model="specialTicket" readonly />
						<!-- <el-select
	      class="labelselect"
	      v-model="FPData.specialTicket"
	      placeholder="请选择"
	    >
	      <el-option key="普票" label="普票" value="0"> </el-option>
	      <el-option key="专票" label="专票" value="1"> </el-option>
	    </el-select> -->
					</div>
				</div>

				<div class="ul">
					<div class="left">统一社会信用代码：</div>
					<div class="right">
						<input type="text" v-model="FPData.unifiedCreditCode" placeholder="请输入" />
					</div>
				</div>

				<div class="ul" v-if="FPData.specialTicket == 1">
					<div class="left">开户银行名称</div>
					<div class="right">
						<input type="text" v-model="FPData.bankName" placeholder="请输入" />
					</div>
				</div>

				<div class="ul" v-if="FPData.specialTicket == 1">
					<div class="left">基本开户账号：</div>
					<div class="right">
						<input type="text" v-model="FPData.bankAccount" placeholder="请输入" />
					</div>
				</div>
				<div class="ul" v-if="FPData.specialTicket == 1">
					<div class="left">注册固定号码：</div>
					<div class="right">
						<input type="text" v-model="FPData.phone" placeholder="请输入" />
					</div>
				</div>
				<div class="ul" v-if="FPData.specialTicket == 1">
					<div class="left">注册场地地址：</div>
					<div class="right">
						<input type="text" v-model="FPData.registerAddress" placeholder="请输入" />
					</div>
				</div>
				<div class="ul">
					<div class="left">备注：</div>
					<div class="right">
						<textarea v-model="FPData.remark" placeholder="请输入" cols="30" rows="20"></textarea>
					</div>
				</div>
				<div class="btn">
					<div class="preservation" @click="EditTicket">提交</div>
					<div class="cancel" @click="FPshow = false">取消</div>
				</div>
			</div>
		</el-drawer>
		<el-dialog title="温馨提示" :visible.sync="YWBLDialog" width="25%" center>
			<div style="width: 100%; text-align: center">
				<span v-if="ispaybycomiddata == 2">您当前未办理网络招聘业务，请前往办理?</span>
				<span v-if="ispaybycomiddata == 3">您当前办理的业务未完成支付，暂不允许该操作，请前往缴费?</span>
				<span v-if="ispaybycomiddata == 4">您当前办理的业务已过期，请前往重新办理?</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" class="footer-btn"
					@click="ispaybycomiddata == 2 ? serviceClick() : (YWBLDialog = false)">确 定</el-button>
				<el-button class="footer-btn" @click="YWBLDialog = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getparameterbytype,
		EditTicket,
		CompanyServiceList,
		ContinuationService,
		subywpayvucher
	} from "@/api/company";
	import utils from "@/assets/js/utils";
	import globalconfig from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	export default {
		props: {
			ispay: {
				type: Boolean,
			},
			isFPshow: {
				type: Boolean,
			},
			navindex: {
				type: Number,
			},
			ywListId: {
				type: String,
			},
		},
		data() {
			return {
				headers: {
					token: localStorage.getItem("token"),
				},
				defimgurl: globalconfig.baseimgurl,
				baseurl: globalconfig.baseurl,
				YWBLDialog: false,
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				userinfo: "",
				AlltableData: [],
				JFdate: ["", ""],
				FPshow: false,
				specialTicket: "普票",
				direction: "rtl",
				Service: false,
				ispays: false,
				FPData: {
					comId: "",
					ywListId: "",
					companyName: "",
					unifiedCreditCode: "",
					specialTicket: "0",
					bankName: "",
					bankAccount: "",
					phone: "",
					registerAddress: "",
					remark: "",
				},
				Service: false,
				ispaybycomiddata: 0,

				JFdata: {
					COM_ID: "",
					payVucher: "",
					cQj: "",
					YW_TYPE: "",
					DBGN: "",
					DEND: "",
					FEE: "0",
					RFee: "",
					payment: 'huikuan',
				},
				JFXLdata: [{
						name: "招聘类别",
						index: 0,
						list: [],
						data: "",
						cCs: ""
					},
					{
						name: "招聘期限",
						index: 0,
						list: [{
								"cName": "一个月"
							},
							{
								cName: "两个月"
							},
							{
								cName: "三个月"
							},
							{
								cName: "四个月"
							},
							{
								cName: "五个月"
							},
							{
								cName: "六个月"
							},
							{
								cName: "一年"
							},
							{
								cName: "两年"
							},
							{
								cName: "三年"
							},
							{
								cName: "四年"
							},
							{
								cName: "五年"
							},
						],
						data: ""
					},
				],
				pickerOptions: {
					disabledDate: (time) => {
						return this.dealDisabledDate(time);
					},
				},
			}
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {
			Service() {
				this.JFXLdata[1].list = [{
						"cName": "一个月"
					},
					{
						cName: "两个月"
					},
					{
						cName: "三个月"
					},
					{
						cName: "四个月"
					},
					{
						cName: "五个月"
					},
					{
						cName: "六个月"
					},
					{
						cName: "一年"
					},
					{
						cName: "两年"
					},
					{
						cName: "三年"
					},
					{
						cName: "四年"
					},
					{
						cName: "五年"
					},
				];
			},
		},
		mounted() {
			this.AllCompanyServiceList();
		},
		watch: {
			ispay() {
				this.Service = true;
			},
			//索要发票多选按钮选取
			isFPshow() {
				if (!this.ywListId) {
					this.$message.warning("请选取至少一条需索要发票的业务!");
					return;
				}
				this.FPData.ywListId = this.ywListId;
				this.FPshow = true;
				this.NEWFPData();
			},
		},
		methods: {
			goto(url) {
				this.$router.push(url);
			},
			//索要发票多选按钮选取
			FPtableAllchange() {
				if (!this.FPData.ywListId) {
					this.$message.warning("请选取至少一条需索要发票的业务!");
					return;
				}
				this.FPshow = true;
				this.NEWFPData();
			},
			NEWFPData() {
				this.FPData = {
					comId: this.userinfo.coM_ID,
					ywListId: this.FPData.ywListId,
					companyName: this.userinfo.coM_NAME,
					unifiedCreditCode: this.userinfo.coM_ZJLB_CODE,
					specialTicket: this.userinfo.specialTicket ? 1 : 0,
					bankName: "",
					bankAccount: "",
					phone: "",
					registerAddress: this.userinfo.specialTicket ?
						this.userinfo.coM_ADDRESS : "",
					remark: "",
				};
				this.specialTicket = this.userinfo.specialTicket ? "专票" : "普票";
			},
			//业务列表
			AllCompanyServiceList() {
				var _this = this;
				_this.FPData.ywListId = "";
				var par = {
					id: this.userinfo.coM_ID,
					types: "1",
				};
				CompanyServiceList(par).then((res) => {
					if (res.success) {
						_this.AlltableData = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Allclose() {
				this.FPData.ywListId = "";
			},
			//索要发票提交
			EditTicket() {
				var _this = this;
				var FPData = _this.FPData;

				if (!FPData.companyName) {
					_this.$message.warning("请输入开票抬头");
					return;
				}
				if (!FPData.unifiedCreditCode) {
					_this.$message.warning("请输入统一社会信用代码");
					return;
				}
				if (!FPData.bankName && FPData.specialTicke == 1) {
					_this.$message.warning("请输入开户银行名称");
					return;
				}
				if (!FPData.bankAccount && FPData.specialTicket == 1) {
					_this.$message.warning("请输入基本开户账号");
					return;
				}
				if (!FPData.phone && FPData.specialTicket == 1) {
					_this.$message.warning("请输入注册固定号码");
					return;
				}
				// if (
				//   !/^1(3|4|5|7|8)\d{9}$/.test(FPData.phone) &&
				//   FPData.specialTicket == 1
				// ) {
				//   _this.$message.warning("注册固定号码格式错误");
				//   return;
				// }

				if (!FPData.registerAddress && FPData.specialTicket == 1) {
					_this.$message.warning("请输入注册场地地址");
					return;
				}

				EditTicket(FPData).then((res) => {
					if (res.success) {
						_this.$message.success("索要发票成功!");
						_this.FPshow = false;
						_this.CompanyServiceList();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			stringToDates(data) {
				return this.utils.stringToDate(data);
			},
			//业务续费选取赋值
			serviceClick(item, ispay = false) {
				this.YWBLDialog = false;
				for (const i in this.JFXLdata) {
					if (i != 1)
						this.getparameterbytype(this.JFXLdata[i].name, i);
				}
				//this.ispay = ispay;
				this.JFdata.RFee = item ? item.fee : "";
				this.ispays = ispay;
				if (ispay) {
					this.JFdata.id = item.id;
				}
				this.Service = true;
				this.JFdata.COM_ID = this.userinfo.coM_ID;
				this.JFdata.cQj = item ? item.cQj : "365";
				this.JFdata.YW_TYPE = item ? item.yW_TYPE : "2102";
				this.JFXLdata[0].data = this.JFdata.YW_TYPE;
				var cCs = "";
				var FEE = 0;
				if (this.JFdata.YW_TYPE == "2101") {
					this.getparameterbytype("普通招聘", 1);
					FEE = 1200
					cCs = "简历下载 1200份，岗位发布 无限"
				} else if (this.JFdata.YW_TYPE == "2102") {
					this.getparameterbytype("热点招聘", 1);
					FEE = 1800
					cCs = "简历下载 无限，岗位发布 无限，招聘会 2次，全年(151*55)LOGO首页图标招聘广告"
				}
				this.JFXLdata[0].cCs = cCs;
				if (this.ywstartdate || item) {
					this.JFdate = [
						this.stringToDates(this.ywstartdate ? this.ywstartdate : item.dend),
						this.utils.dateChange(
							item ? item.cQj : "",
							this.stringToDates(this.ywstartdate ? this.ywstartdate : item.dend)
						),
					];
				} else {
					//this.JFdate = [];
					this.JFdate = [_this.utils.dateChange(), _this.utils.dateChange(365)];
				}
				this.JFdata.FEE = item ? item.fee : FEE;
				this.JFdata.RFee = item ? item.RFee : FEE;
				this.JFdata.payVucher = item ? item.payVucher : "";
			},
			//缴费下拉
			getparameterbytype(types, i) {
				var _this = this;
				getparameterbytype({
					types: types
				}).then((res) => {
					if (res.success) {
						_this.JFXLdata[i].list = res.data;
						_this.JFXLdata[i].data = "";
						for (const j in res.data) {
							if (i == 0 && _this.JFdata.YW_TYPE == res.data[j].cCode) {
								_this.JFXLdata[i].data = res.data[j].cName;
							}
							if (i == 1 && _this.JFdata.cQj == res.data[j].cRemark) {
								_this.JFXLdata[i].data = res.data[j].cName;
							}
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			JFChange1() {
				this.$message.error("请先选择业务类别");
			},
			//缴费下拉选取
			JFChange(e, i) {
				var _this = this;
				var j = "";
				_this.JFXLdata[i].list.forEach((element, index) => {
					if (i == 0 && element.cCode == e) {
						j = index;
						_this.JFXLdata[i].data = element.cName;
						_this.JFXLdata[i].cCs = element.cCs;
					}
					if (i == 1 && element.cRemark == e) {
						j = index;
						_this.JFXLdata[i].data = element.cName;
					}
					if (i == 2 && element.cRemark == e) {
						j = index;
						_this.JFXLdata[i].data = element.cName;
					}
				});
				switch (i) {
					case 0:
						_this.JFdata.YW_TYPE = e;
						if (e == "2101") {
							this.getparameterbytype("普通招聘", 1);
						} else if (e == "2102") {
							this.getparameterbytype("热点招聘", 1);
						} else if (e == "999") {
							this.getparameterbytype("首页banner期限", 1);
						}
						_this.JFdata.cQj = "365";
						_this.JFXLdata[1].data = "全年"
						var cCs = "";
						var FEE = 0;
						if (_this.JFdata.YW_TYPE == "2101") {
							FEE = 1200
							cCs = "简历下载 1200份，岗位发布 无限"
						} else {
							FEE = 1800
							cCs = "简历下载 无限，岗位发布 无限，招聘会 2次，全年(151*55)LOGO首页图标招聘广告"
						}
						_this.JFdata.FEE = FEE;
						_this.JFdata.RFee = FEE;
						_this.JFXLdata[0].cCs = cCs;
						_this.JFdate = [_this.utils.dateChange(), _this.utils.dateChange(365)];
						break;
					case 1:
						_this.JFXLdata[1].data = e.cName;
						_this.JFdata.cQj = e.cRemark;
						_this.JFdate = [_this.utils.dateChange(), _this.utils.dateChange(e.cRemark)];
						var FEE = e.cCs;
						_this.JFdata.FEE = FEE;
						_this.JFdata.RFee = FEE;
						var cCs = "";
						if (_this.JFdata.YW_TYPE == "2101") {
							switch (FEE) {
								default:
									cCs = "简历下载 100份，岗位发布 无限"
									break;
								case "240":
									cCs = "简历下载 300份，岗位发布 无限"
									break;
								case "800":
									cCs = "简历下载 600份，岗位发布 无限"
									break;
								case "1200":
									cCs = "简历下载 1200份，岗位发布 无限"
									break;
							}
						} else if (_this.JFdata.YW_TYPE == "2102") {
							switch (FEE) {
								default:
									cCs = "简历下载 无限，岗位发布 无限，招聘会 2次，全年(151*55)LOGO首页图标招聘广告"
									break;
							}
						}
						_this.JFXLdata[0].cCs = cCs;
						break;
					default:
						break;
				}
			},
			//表格全选
			handleSelectionChange(data) {
				var list = "";
				for (const i in data) {
					list = list + data[i].id;
					if (parseInt(i) + 1 < data.length) {
						list = list + ",";
					}
				}
				this.FPData.ywListId = list;
			},
			selectable(row, index) {
				if (row.isTicket == 1) {
					return false;
				} else {
					if (row.isPay) {
						return true;
					} else {
						return false;
					}
				}
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.urlpost();
				// this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				if (!this.dialogTable) {
					this.urlpost();
				} else {
					this.AllCompanyServiceList();
				}
			},
			//业务续费
			ContinuationService() {
				var _this = this;
				var JFdata = _this.JFdata;
				JFdata.DBGN = _this.JFdate[0];
				JFdata.DEND = _this.JFdate[1];

				if (!JFdata.YW_TYPE && !_this.ispays) {
					_this.$message.warning("请选取业务类别");
					return;
				}
				if (!JFdata.cQj && !_this.ispays) {
					_this.$message.warning("请选取业务期限");
					return;
				}
				if (!JFdata.DEND && !_this.ispays) {
					_this.$message.warning("请选取业务起止日期");
					return;
				}

				if (!JFdata.payVucher && _this.ispays) {
					_this.$message.warning("请上传缴费凭证");
					return;
				}
				if (JFdata.payVucher) {
					_this.JFdata.isPay = 1;
				} else {
					_this.JFdata.isPay = 0;
					_this.JFdata.RFee = 0;
				}

				if (_this.ispays) {
					var par = {
						isPay: 1,
						payVucher: JFdata.payVucher,
						RFee: JFdata.RFee,
						ID: JFdata.id,
					};
					subywpayvucher(par).then((res) => {
						if (res.success) {
							_this.$message.success("缴费成功!");
							_this.Service = false;
							_this.AllCompanyServiceList();
						} else {
							_this.$message.error(res.msg);
						}
					});
				} else {
					ContinuationService(JFdata).then((res) => {
						if (res.success) {
							_this.$message.success("续交成功!");
							_this.Service = false;
							_this.AllCompanyServiceList();
						} else {
							_this.$message.error(res.msg);
						}
					});
				}
			},
			//上传缴费凭证成功
			uploadJFSuccess(res, file) {
				this.$message.success("上传成功!");
				this.JFdata.payVucher = res.data.obj.files;
			},
			uploadimageerror(res, file) {
				this.$message.error("上传失败!");
			},
		},
	}
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.post {
		width: 100%;

		.mainTitle {
			font-size: 14px;
			color: #333;

			.main {
				font-size: 28px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
		}

		.release {
			width: 120px;
			height: 30px;
			font-size: 14px;
			background: #467DF2;
			color: white;
			border-radius: 5px;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
			margin-left: 10px;
		}

		.releases {
			text-align: center;
			cursor: pointer;
			display: flex;
			align-items: center;

			i {
				font-size: 22px;
				margin-right: 5px;
			}
		}
	}

	.postadd {
		padding: 5px 20px;

		.li {
			width: 50%;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.left {
				width: 20%;
				font-size: 14px;
				font-weight: bold;
			}

			.right {
				width: 80%;
				font-size: 14px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 300px;
				}

				.labelselect {
					height: 40px;

					width: 300px;

					.el-input__inner {
						width: 300px;
					}
				}
			}
		}

		.ul {
			width: 100%;

			.left {
				font-size: 14px;
				width: 100px;
				color: #010101;
				line-height: 32px;
			}

			.right {
				font-size: 14px;
				line-height: 32px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 100%;
					line-height: 30px;
				}

				.labelselect {
					height: 40px;

					width: 100%;

					.el-input__inner {
						width: 100%;
					}
				}

				textarea {
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					padding: 10px 5px;
					width: 100%;
				}

				.Tips {
					color: #0089FF;
					font-size: 12px
				}
			}
		}

		.box-dialog {
			border: 1px solid #eee;
			margin-top: 20px;

			.box-header {
				padding: 10px;
				font-size: 14px;
				color: #010101;
			}

			.box-body {
				padding: 10px;
				overflow: hidden;

				.labelselect {
					cursor: pointer;
					border: 1px solid #0089FF;
					color: #0089FF;
					float: left;
					line-height: 30px;
					width: 60px;
					text-align: center;
				}

				.itemClick {
					background: #0089FF;
					color: #fff;
				}
			}

			.box-left {
				width: 116px;

				.onclick {
					background-color: #f5f5f5;
					color: #898989;
					font-size: 14px;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}

			.box-right {
				width: 100%;
				padding: 0 15px;
				border-left: 1px solid #eee;

				p {
					color: #101010;
					font-size: 14px;
					display: flex;
					align-items: center;

					b {
						font-size: 32px
					}
				}

				span {
					font-size: 18px;
				}
			}
		}

		.btn {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 120px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #276bf2;
				color: white;
				cursor: pointer;
			}
		}
	}
</style>
