<template>
	<div>
		<div class="Ctitle">公司信息</div>
		<!-- <div class="Ctitle">
			<div :class="['tab',isActive == 0 ? 'tab-active' : '']" @click="handleClick(0)">基本信息</div>
			<div :class="['tab',isActive == 1 ? 'tab-active' : '']" @click="handleClick(1)">资质认证</div>
			<div :class="['tab',isActive == 2 ? 'tab-active' : '']" @click="handleClick(2)">公司荣誉</div>
			<div :class="['tab',isActive == 3 ? 'tab-active' : '']" @click="handleClick(3)">公司环境</div>
		</div> -->
		<div class="module" v-if="isActive == 0">
			<div class="label flex1">
				<div class="labelLine">
					<div class="labelName">企业全称：</div>
					<input type="text" v-model="editinfo.coM_NAME" placeholder="请输入" readonly />
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>从事行业：</div>
					<el-select class="labelselect" v-model="sxdata[0].data" @change="SelectChange($event, 0)"
						placeholder="请选择">
						<el-option v-for="item in sxdata[0].list" :key="item.cName" :label="item.cName"
							:value="item.cCode">
						</el-option>
					</el-select>
				</div>
				<!-- 公司规模： -->
				<div class="labelLine">
					<div class="labelName"><b>*</b>企业规模：</div>
					<el-select class="labelselect" v-model="sxdata[2].data" @change="SelectChange($event, 2)"
						placeholder="请选择">
						<el-option v-for="item in sxdata[2].list" :key="item.cName" :label="item.cName"
							:value="item.cCode">
						</el-option>
					</el-select>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>企业性质：</div>
					<el-select class="labelselect" v-model="sxdata[3].data" @change="SelectChange($event, 3)"
						placeholder="请选择">
						<el-option v-for="item in sxdata[3].list" :key="item.cName" :label="item.cName"
							:value="item.cCode">
						</el-option>
					</el-select>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>所在地区：</div>
					<el-select class="labelselect" v-model="editinfo.coM_STREET_CODE" placeholder="请选择">
						<el-option v-for="item in Street" :key="item.name" :label="item.name" :value="item.name">
						</el-option>
					</el-select>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>详细地址：</div>
					<div style="position: relative;">
						<input type="text" v-model="editinfo.coM_ADDRESS" placeholder="请输入" />
						<i class="el-icon el-icon-map-location"></i>
					</div>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>企业简介：</div>
					<textarea v-model="editinfo.coM_INTRODUCT" cols="30" rows="10"></textarea>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>联系人：</div>
					<input type="text" v-model="editinfo.coM_LINKMAN" placeholder="请输入" />
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>联系电话：</div>
					<input type="text" v-model="editinfo.coM_MPHONE" placeholder="请选择" />
				</div>
				<div class="labelLine">
					<div class="labelName">固定电话：</div>
					<input type="text" v-model="editinfo.coM_PHONE" placeholder="请选择" />
				</div>
				<div class="labelLine">
					<div class="labelName">企业邮箱：</div>
					<input type="text" v-model="editinfo.coM_EMAIL" placeholder="请输入" />
				</div>
				<div class="labelLine flex1">
					<div class="labelName">招聘大类：</div>
					<input type="text" readonly="true" v-if="!btn" v-model="sxdata[4].data" placeholder="请输入" />
					<el-select class="labelselect" v-model="sxdata[4].data" @change="SelectChange($event, 4)" v-else
						placeholder="请选择">
						<el-option v-for="item in sxdata[4].list" :key="item.cName" :label="item.cName"
							:value="item.cCode">
						</el-option>
					</el-select>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>企业LOGO：</div>
					<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
						:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
						:on-success="uploadLogoSuccess" :on-error="uploadimageerror">
						<img v-if="editinfo.coM_LOGO!=''" style="width: 100px; height: 100px" fit="contain"
							:src="defimgurl + editinfo.coM_LOGO" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<span>1.请用jpg,png,jpeg,bmp,gif格式的图片,大小不超过2M;<br>
						2.尺寸不小于300*300像素，正方形1:1效果最佳.</span>
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>统一社会信用代码：</div>
					<input type="text" v-model="editinfo.coM_ZJLB_CODE" placeholder="请输入" />
				</div>
				<div class="labelLine">
					<div class="labelName"><b>*</b>证明材料：</div>
					<div>
						<span>营业执照需是在有效期范围内的原件照片，扫描或者加盖公章的复印件，若无营业执照可上传组织机构代码证</span>
						
						<el-upload class="avatar-uploader" :action="baseurl + '/Admin/Upload/uploadimage'"
							:headers="headers" accept="image/png, image/jpeg" :show-file-list="false"
							:on-success="uploadimageSuccess" :on-error="uploadimageerror">
							<img v-if="editinfo.coM_VOUCHER!=''" style="width: 100px; height: 100px" fit="contain"
								:src="defimgurl + editinfo.coM_VOUCHER" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<div class="labelLine flex1">
					<div class="labelName"></div>
					<div class="moduleBTN">
						<div class="preservation" @click="opercompanyinfo">保存</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module" v-if="isActive == 1">
			<div class="label flex1">
			</div>
		</div>
		<div class="module" v-if="isActive == 2">
		</div>

		<div class="module" v-if="isActive == 3">
			<div class="labelLine flex1">
				<div class="labelName">证件类别：</div>
				<input type="text" readonly="true" v-if="!btn" v-model="sxdata[1].data" placeholder="请输入" />
				<el-select class="labelselect" v-model="sxdata[1].data" @change="SelectChange($event, 1)" v-else
					placeholder="请选择">
					<el-option v-for="item in sxdata[1].list" :key="item.cName" :label="item.cName" :value="item.cCode">
					</el-option>
				</el-select>
			</div>
			<!-- 营业执照 -->

			<span></span>
			<div class="moduleBTN" v-if="btn" @click="cancel">
				<div class="preservation" @click="opercompanyinfo">保存</div>
				<div class="cancel">取消</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		posilistall,
		opercompanyinfo
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {},
		data() {
			return {
				defimgurl: global.baseimgurl,
				baseurl: global.baseurl,
				userinfo: "",
				editinfo: "",
				btn: false,
				isActive: 0,
				Street: [{
						name: "古城街道",
					},
					{
						name: "大洋街道",
					},
					{
						name: "江南街道",
					},
					{
						name: "大田街道",
					},
					{
						name: "邵家渡街道",
					},
					{
						name: "汛桥镇",
					},
					{
						name: "东塍镇",
					},
					{
						name: "汇溪镇",
					},
					{
						name: "小芝镇",
					},
					{
						name: "河头镇",
					},
					{
						name: "白水洋镇",
					},
					{
						name: "括苍镇",
					},
					{
						name: "永丰镇",
					},
					{
						name: "尤溪镇",
					},
					{
						name: "涌泉镇",
					},
					{
						name: "沿江镇",
					},
					{
						name: "杜桥镇",
					},
					{
						name: "上盘镇",
					},
					{
						name: "桃渚镇",
					},
					{
						name: "其他"
					},
				],
				sxdata: [{
						name: "所属行业",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "证件类别",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "公司规模",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "公司性质",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "招聘类别",
						index: 0,
						list: [],
						data: ""
					},
				],
				headers: {
					token: localStorage.getItem("token"),
				},
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.editinfo = JSON.parse(JSON.stringify(this.userinfo));
		},
		watch: {
			"$store.state.userinfo": {
				handler: function(newVal, oldVal) {
					this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
					this.editinfo = JSON.parse(JSON.stringify(this.userinfo));
				},
			},
		},
		mounted() {
			for (const i in this.sxdata) {
				this.posilistall(i);
			}
		},
		methods: {
			handleClick(i) {
				this.isActive = i;
			},
			//企业信息下拉数据
			posilistall(i) {
				var _this = this;
				var par = {
					types: _this.sxdata[i].name,
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.sxdata[i].list = res.data.rows;
						res.data.rows.forEach((element, index) => {
							switch (i) {
								case "0":
									if (element.cCode == _this.userinfo.coM_INDUSTRY) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "1":
									if (element.cCode == _this.userinfo.coM_ZJLB) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "2":
									if (element.cCode == _this.userinfo.coM_SCALE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "3":
									if (element.cCode == _this.userinfo.coM_NATURE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								case "4":
									if (element.cCode == _this.userinfo.coM_HYTYPE) {
										_this.sxdata[i].data = element.cName;
										_this.sxdata[i].index = index;
									}
									break;
								default:
									break;
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//下拉框选取
			SelectChange(e, i) {
				var _this = this;
				_this.sxdata[i].list.forEach((element) => {
					if (element.cCode == e) {
						_this.sxdata[i].data = element.cName;
					}
				});
				switch (i) {
					case 0:
						_this.editinfo.coM_INDUSTRY = e;

						break;
					case 1:
						_this.editinfo.coM_ZJLB = e;

						break;
					case 2:
						_this.editinfo.coM_SCALE = e;

						break;
					case 3:
						_this.editinfo.coM_NATURE = e;

						break;
					case 4:
						_this.editinfo.coM_HYTYPE = e;

						break;

					default:
						break;
				}
			},
			cancel: function() {
				this.editBtn = true;
				this.editinfo = JSON.parse(JSON.stringify(this.userinfo));
				this.btn = false;
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					this.defimgurl + url;
			},
			//公司修改
			opercompanyinfo(flag) {
				var _this = this;
				var editinfo = _this.editinfo;
				if (!editinfo.coM_NAME) {
					_this.$message.warning("请输入公司名称");
					return;
				}
				if (!editinfo.coM_SCALE) {
					_this.$message.warning("请选择公司规模");
					return;
				}
				if (!editinfo.coM_INDUSTRY) {
					_this.$message.warning("请选择所属行业");
					return;
				}
				if (!editinfo.coM_STREET_CODE) {
					_this.$message.warning("请选择所属镇街");
					return;
				}
				if (!editinfo.coM_LINKMAN) {
					_this.$message.warning("请输入联系人");
					return;
				}
				if (!editinfo.coM_MPHONE) {
					_this.$message.warning("请输入手机号码");
					return;
				}
				if (!editinfo.coM_VOUCHER) {
					_this.$message.warning("请上传营业执照");
					return;
				}
				var par = {
					COM_ID: editinfo.coM_ID, //公司ID
					COM_NAME: editinfo.coM_NAME, //公司名
					COM_LINKMAN: editinfo.coM_LINKMAN, //联系人
					COM_ZJLB: editinfo.coM_ZJLB, //证件类别
					COM_ZJLB_CODE: editinfo.coM_ZJLB_CODE, //证件编号
					COM_MPHONE: editinfo.coM_MPHONE, //手机号码
					COM_PHONE: editinfo.coM_PHONE, //联系电话
					COM_ZIPCODE: editinfo.coM_ZIPCODE, //邮编
					COM_NATURE: editinfo.coM_NATURE, //公司性质
					COM_SCALE: editinfo.coM_SCALE, //公司规模
					COM_WEBSITE: editinfo.coM_WEBSITE, //公司网址
					COM_INDUSTRY: editinfo.coM_INDUSTRY, //所属行业
					COM_EMAIL: editinfo.coM_EMAIL, //公司邮箱
					COM_HYTYPE: editinfo.coM_HYTYPE, //招聘大类
					COM_VOUCHER: editinfo.coM_VOUCHER, //营业执照
					COM_INTRODUCT: editinfo.coM_INTRODUCT, //公司介绍
					COM_ADDRESS: editinfo.coM_ADDRESS, //地址
					COM_LOGO: editinfo.coM_LOGO,
					COM_STREET_CODE: editinfo.coM_STREET_CODE, //coM_STREET_CODE
				};
				opercompanyinfo(par).then((res) => {
					if (res.success) {
						// res.data.rows.forEach((element) => {
						//   element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						// });
						// _this.total = res.data.total;
						// _this.postList = res.data.rows;
						if (!flag) {
							_this.$message.success("保存成功!");
						}
						this.$emit("infoedit", "");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//上传执照成功
			uploadimageSuccess(res, file) {
				this.$message.success("上传成功!");
				this.editinfo.coM_VOUCHER = res.data.obj.files;
			},
			//上传logo成功
			uploadLogoSuccess(res, file) {
				this.$message.success("上传成功!");
				this.editinfo.coM_LOGO = res.data.obj.files;
			},
			uploadimageerror(res, file) {
				this.$message.error("上传失败!");
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.module {
		width: 100%;
		box-sizing: border-box;
		background: white;
		padding: 20px 60px;
		font-size: 14px;
		color: #333;
		margin-bottom: 10px;

		.moduleEdit {
			color: #666;
		}

		.moduleEdit:hover {
			cursor: pointer;
		}

		.moduleTitle {
			font-size: 16px;
			color: #333;
			font-weight: bold;
			margin-bottom: 25px;

			img {
				width: 30px;
				margin-right: 10px;
			}

			b {
				color: red;
				position: relative;
				top: 5px;
				left: 2px;
			}
		}

		.label {
			width: 100%;

			textarea {
				box-sizing: border-box;
				border: 1px solid rgba(193, 189, 189, 0.4);
				border-radius: 4px;
				text-indent: 1em;
				padding: 10px 5px;
				width: 100%;
			}

			.labelLine {
				width: 100%;
				margin-bottom: 16px;
				position: relative;
				display: flex;
				flex-wrap: wrap;

				.labelName {
					width: 160px;
					height: 36px;
					line-height: 36px;
					//text-align: right;
				}

				input {
					height: 36px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 405px;
				}

				textarea {
					width: 644px;
				}

				.labelselect::v-deep {
					height: 36px !important;
					line-height: 36px !important;
					width: 405px;

					.el-input__inner {
						height: 36px !important;
						line-height: 36px !important;
						width: 405px;
					}
				}

				span::v-deep {
					color: #666;
					height: 36px;
					line-height: 36px;
					font-size: 14px;
					padding-left: 15px;
					display: flex;
					align-items: center;
				}

				b {
					color: red;
				}

				i.el-icon {
					color: #bfc2cc;
					position: absolute;
					right: 10px;
					top: 10px;
				}
			}
		}

		.moduleBTN {
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 169px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
				border-radius: 20px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #0096FF;
				color: white;
				cursor: pointer;
			}
		}

		.license {
			margin: 15px 0;
			width: 280px;
			height: 160px;
			line-height: 160px;
			text-align: center;
			overflow: hidden;
			color: #999;
			font-size: 20px;
			background: #f8f8f8;
		}
	}
</style>
