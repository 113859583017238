<template>
	<div>
		<div v-if="navindex == 2">
			<div class="Ctitle">部门列表</div>
			<div class="post">
				<div class="search flex2">
					<ul class="search-ul">
						<li>
							<label class="layui-text">关键字：</label>
							<input v-model="searchKey" placeholder="请输入关键字" class="search-input"></input>
						</li>
						<li class="clickSearch">
							<el-button type="primary" class="layui-btn layui-btn-primary" v-preventReClick
								@click="urlpost()">搜索</el-button>
						</li>
					</ul>
					<div class="flex">
						<div class="release" @click="FPtableAllchange('del')">删除</div>
						<div class="release" @click="drawerChange()">添加</div>
					</div>
				</div>
			</div>
			<div class="postList">
				<template>
					<el-table :data="postList" stripe style="width: 100%" header-row-class-name="headerrowclass"
						row-class-name="rowclass" @selection-change="handleSelectionChange">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column label="部门名称">
							<template slot-scope="scope">
								{{ scope.row.dep_title }}
							</template>
						</el-table-column>
						<el-table-column label="联系人">
							<template slot-scope="scope">
								{{ scope.row.dep_name }}
							</template>
						</el-table-column>
						<el-table-column label="联系电话">
							<template slot-scope="scope">
								{{ scope.row.dep_tel }}
							</template>
						</el-table-column>
						<el-table-column label="地址" width="100">
							<template slot-scope="scope">
								{{ scope.row.dep_address }}
							</template>
						</el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<a @click="editposi(scope.row)" style="color: #276bf2;margin-right: 10px;">编辑</a>
								<a @click="delposi(scope.row)" style="color: #276bf2;">删除</a>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>

			<div class="page" v-if="postList.length > 0 && total > 10">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
					:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<base-drawer :visible.sync="drawer" :size="460">
			<div slot="header">
				<div class="drawer-head">编辑部门</div>
			</div>
			<el-form style="margin-top: 15px;" label-width="80px">
				<b class="RequiredFlag">*</b>
				<el-form-item label="部门名称:">
					<el-input v-model="adddata.dep_title"></el-input>
				</el-form-item>
				<el-form-item label="联系人:">
					<el-input v-model="adddata.dep_name"></el-input>
				</el-form-item>
				<el-form-item label="联系电话:">
					<el-input v-model="adddata.dep_tel"></el-input>
				</el-form-item>
				<el-form-item label="部门地址:">
					<el-input v-model="adddata.dep_address"></el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" v-preventReClick @click="postAdd()">提交</el-button>
		</base-drawer>
	</div>
</template>
<script>
	import {
		addComDep,
		ComDepList,
		delComDep
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	export default {
		props: {
			depDrawer: {
				type: Boolean,
			},
			navindex: {
				type: Number,
			},
		},
		data() {
			return {
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				userinfo: "",
				postList: [],
				direction: "rtl",
				adddata: "",
				ISClick: '',
				searchKey: '',
				POSINAME: '不限',
				GZJY: '不限',
				types: "",
				checkList: [],
				ywListId: "",
				drawer:false
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {
			depDrawer(n, o) {
				this.drawerChange();
			},
			"$store.state.userinfo": {
				handler: function(newVal, oldVal) {
					this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
					this.timer = setInterval(this.test, 1000);
				},
			},
		},
		mounted() {
			this.ComDepList();
		},
		methods: {
			//表格选取
			FPtablechange(id) {
				this.ywListId = id;
			},
			//表格全选
			handleSelectionChange(data) {
				var list = "";
				for (const i in data) {
					list = list + data[i].id;
					if (parseInt(i) + 1 < data.length) {
						list = list + ",";
					}
				}
				this.ywListId = list;
			},
			//批量操作
			FPtableAllchange(e) {
				if (!this.ywListId) {
					this.$message.warning("请选取至少一条需要操作的数据!");
					return;
				}
				var par = {
					id: this.userinfo.coM_ID,
					cid: this.ywListId,
				};
				delComDep(par).then((res) => {
					_this.$message.success("操作成功!");
					this.PageNumber = 1;
					this.ComDepList();
				});
			},
			setsalary(a, b) {
				return utils.setsalary(a, b);

			},

			urlpost() {
				this.CurrentChange(1);
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.ComDepList();
				this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.ComDepList();
				this.goPost();
			},
			//岗位列表
			ComDepList() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					KW: _this.searchKey,
					// showLoadType: ".resume",
				};
				ComDepList(par).then((res) => {
					if (res.success) {
						_this.total = res.data.total;
						_this.postList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			//删除
			delposi(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					cid: id,
				};
				delComDep(par).then((res) => {
					if (res.success) {
						this.PageNumber = 1;
						_this.ComDepList();
						_this.$message.success("部门已删除!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//新增岗位重置数据
			drawerChange() {
				var _this = this;
				_this.adddata = {
					com_id: "", //公司ID
					dep_title: "",
					dep_name: "",
					dep_tel: "",
					dep_address: "",
				};
				_this.drawer = true;
			},
			//岗位修改赋值
			editposi(item) {
				var _this = this;
				_this.adddata = {
					id: item.id,
					com_id: item.com_id, //公司ID
					dep_title: item.dep_title,
					dep_name: item.dep_name,
					dep_tel: item.dep_tel,
					dep_address: item.dep_address,
				};
				_this.drawer = true;
			},
			//岗位新增
			postAdd() {
				var _this = this;
				_this.adddata.com_id = _this.userinfo.coM_ID; //公司ID
				var adddata = _this.adddata;
				if (!adddata.dep_title) {
					_this.$message.warning("请输入部门名称");
					return;
				}
				addComDep(this.adddata).then((res) => {
					if (res.success) {
						_this.$message.success("岗位新增成功!");
						_this.drawer = false;
						this.PageNumber = 1;
						this.navindex = 2;
						_this.ComDepList();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			goPost() {
				let distance =
					document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				let step = distance / 30; //每步的距离
				(function jump() {
					if (distance > 0) {
						distance -= step;
						window.scrollTo(0, distance);
						setTimeout(jump, 10);
					}
				})();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	} 

	.post {
		width: 100%;

		.mainTitle {
			font-size: 14px;
			color: #333;

			.main {
				font-size: 28px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
		}

		.release {
			width: 90px;
			height: 30px;
			font-size: 14px;
			background: #467DF2;
			color: white;
			border-radius: 5px;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
			margin-left: 10px;
		}

		.releases {
			text-align: center;
			cursor: pointer;
			display: flex;
			align-items: center;

			i {
				font-size: 22px;
				margin-right: 5px;
			}
		}
	}


	.btn-red {
		background-color: #F50909 !important;
	}

	.btn-orange {
		background-color: #FF6000 !important;
	}

	.btn-bule {
		background-color: #467DF2 !important;
	}

	.postList {
		width: 100%;
		padding: 20px;
		background: white;
		box-sizing: border-box;
		color: #999;
		font-size: 14px;
		margin-bottom: 10px;

		.btnlist {
			.btn {
				height: 30px;
				line-height: 30px;
				color: #fff;
				padding: 0;
				width: 96px;
			}

		}


		.postul {
			display: flex;

			.postLeft {
				width: 85%;
				display: flex;

				.left {
					width: 50%;
					border-right: 1px solid #edf1f5;
				}

				.right {
					width: 50%;
					padding-left: 10px;
					position: relative;

					.time {
						margin-top: 15px;
						position: absolute;
						bottom: 0;
					}
				}
			}

			.postRight {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				width: 15%;

				.top {
					margin-bottom: 15px;

					.cszp {
						color: #fff;
						margin-left: 10px;
						border-radius: 5px;

						font-size: 13px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}

				.bottom {
					display: flex;

					.postEdit {
						// width: 105px;
						// height: 35px;
						// background: #276bf2;
						// border-radius: 4px;
						// text-align: center;
						// line-height: 35px;
						font-size: 15px;
						margin-right: 12px;
						display: flex;
						align-items: center;
						color: #276bf2;
						cursor: pointer;

						i {
							color: #276bf2;
							margin-right: 4px;
							font-size: 22px;
							font-weight: bold;
						}
					}

					.postDel {
						// width: 105px;
						// height: 35px;
						// border-radius: 4px;
						// border: 1px solid #bbb;
						// box-sizing: border-box;
						// text-align: center;
						// line-height: 35px;
						// color: #666;
						font-size: 15px;

						display: flex;
						align-items: center;
						color: #f56c6c;
						cursor: pointer;

						i {
							color: #f56c6c;
							margin-right: 4px;
							font-size: 22px;
							font-weight: bold;
						}
					}
				}
			}
		}

		.postSalary {
			margin-top: 15px;
		}

		.postName {
			color: #276bf2;
			font-size: 16px;
			margin-right: 10px;
		}

		.status {
			font-size: 14px;
			margin-right: 10px;
		}

		.date {
			color: #666;
		}

		.salary {
			color: #ff7630;
			font-size: 16px;
			margin-right: 20px;
		}

		.postFactor {
			margin-right: 10px;
		}

		.welfare {
			background: #f8f8f8;
			color: #6889aa;
			font-size: 12px;
			margin-right: 10px;
			margin-top: 5px;
		}
	}

	.postList:hover {
		box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
	}

	.postadd {
		padding: 5px 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.Tips {
			font-size: 14px;
			margin-bottom: 10px;
		}

		.TipsM {
			margin-bottom: 30px;
		}

		.li {
			width: 50%;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.left {
				width: 20%;
				font-size: 14px;
				font-weight: bold;
			}

			.right {
				width: 80%;
				font-size: 14px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 300px;
				}

				.labelselect {
					height: 40px;
					width: 300px;

					.el-input__inner {
						width: 300px;
					}
				}
			}

			.rights {
				width: 80%;
				font-size: 14px;
				display: flex;
				align-items: center;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 37%;
				}

				span {
					margin: 0 10px;
				}
			}

			b {
				color: red;
			}
		}

		.ul {
			width: 100%;
			margin-bottom: 20px;

			.left {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 10px;
			}

			.right {
				width: 100%;
				font-size: 14px;

				.el-checkbox {
					width: 80px;
					height: 40px;
					line-height: 40px;
				}

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 100%;
				}

				.labelselect {
					height: 40px;

					width: 100%;

					.el-input__inner {
						width: 100%;
					}
				}

				textarea {
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					padding: 10px 5px;
					width: 100%;
				}
			}

			b {
				color: red;
			}
		}

		.btn {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 120px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #276bf2;
				color: white;
				cursor: pointer;
			}
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			/deep/.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			/deep/.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			/deep/.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.red {
		color: #ff0000;
	}

	.green {
		color: #1f9b29;
	}

	.yellow {
		color: #ffb800;
	}

	.blue {
		color: #276bf2;
	}

	.orgin {
		color: #fb6703;
	}

	.hui {
		color: #999999;
	}

	.footer-btn {
		width: 150px;
	}
</style>
