<template>
	<div>
		<div v-if="navindex == 3">
			<div class="Ctitle">招聘岗位列表</div>
			<div class="post">
				<div class="search flex2">
					<ul class="search-ul">
						<li>
							<label class="layui-text">关键字：</label>
							<input v-model="searchKey" placeholder="请输入关键字" class="search-input"></input>
						</li>
						<li class="select-div">
							<label class="layui-text">求职状态：</label>
							<el-select v-model="types" placeholder="全部">
								<el-option label="全部" value=""></el-option>
								<el-option label="招聘中" value="0"></el-option>
								<el-option label="停止招聘" value="1"></el-option>
							</el-select>
						</li>
						<li class="clickSearch">
							<el-button type="primary" class="layui-btn layui-btn-primary" v-preventReClick
								@click="urlpost()">搜索</el-button>
						</li>
					</ul>
					<div class="flex">
						<!-- <div class="release" @click="FPtableAllchange('del')">删除</div>
						<div @click="FPtableAllchange('STATUS1')" class="release">停止招聘</div>
						<div @click="FPtableAllchange('STATUS0')" class="release">激活</div> -->
						<div class="release" @click="ispaybycomiddata == 0 ? drawerChange() : (YWBLDialog = true)">发布岗位</div>
					</div>
				</div>
			</div>
			<div class="postList">
				<template>
					<el-table :data="postList" stripe style="width: 100%" header-row-class-name="headerrowclass"
						row-class-name="rowclass" @selection-change="handleSelectionChange">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column label="职位名称">
							<template slot-scope="scope">
								{{ scope.row.oF_POSI_NAME }}
							</template>
						</el-table-column>
						<el-table-column label="基本信息(性质/学历/薪资)">
							<template slot-scope="scope">
								{{scope.row.oF_POSI_NATURE ? scope.row.oF_POSI_NATURE : "无"}}
								/
								{{scope.row.oF_EDU_REQ ? scope.row.oF_EDU_REQ : "无"}}
								/
								{{
								  scope.row.oF_SALARY_MY == 1
								    ? "面议"
								    : setsalary(scope.row.oF_SALARY, scope.row.oF_SALARYE)
								}}
							</template>
						</el-table-column>
						<el-table-column label="招聘人数" width="100">
							<template slot-scope="scope">
								{{ scope.row.oF_INVITE_NUM }} 人
							</template>
						</el-table-column>
						<el-table-column label="状态" width="100">
							<template slot-scope="scope">
								<div :class="['status', scope.row.oF_STATUS == 0 ? 'green' : 'red']">
									{{ scope.row.oF_STATUS == 0 ? "招聘中" : "停止招聘" }}
								</div>
							</template>
						</el-table-column>
						<el-table-column label="发布时间" width="120">
							<template slot-scope="scope">
								{{ stringToDates(scope.row.oF_REDATE) }}
							</template>
						</el-table-column>
						<el-table-column label="有效期" width="120">
							<template slot-scope="scope">
								{{ stringToDates(scope.row.oF_DEADLINE) }}
							</template>
						</el-table-column>

						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<a @click="editposi(scope.row)" style="color: #276bf2;margin-right: 10px;">编辑</a>
								<el-dropdown
									@command="(command) => {handleCommand1(command, scope.$index, scope.row);}">
									<span class="el-dropdown-link">更多</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="d">删除</el-dropdown-item>
										<el-dropdown-item command="t">停止</el-dropdown-item>
										<el-dropdown-item command="j">激活</el-dropdown-item>
										<el-dropdown-item command="s">刷新</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>

			<div class="page" v-if="postList.length > 0 && total > 10">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
					:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!-- 岗位 -->
		<el-drawer :wrapperClosable="false" title="发布岗位" size="957px" :visible.sync="drawer" :direction="direction">
			<div class="postadd">
				<div class="li">
					<div class="left"><b>*</b>岗位名称:</div>
					<div class="right">
						<input type="text" v-model="adddata.OF_POSI_NAME" placeholder="请输入" />
					</div>
				</div>
				<div class="li">
					<div class="left"><b>*</b>岗位类型:</div>
					<div class="rights">
						<el-select class="labelselect" v-model="sxdata[2].data" @change="SelectChange($event, 2)"
							placeholder="请选择">
							<el-option v-for="item in sxdata[2].list" :key="item.cName" :label="item.cName"
								:value="item.cCode">
							</el-option>
						</el-select>
						<el-select class="labelselect" v-model="sxdata[3].data" @change="SelectChange($event, 3)"
							placeholder="请选择">
							<el-option v-for="item in sxdata[3].list" :key="item.cName" :label="item.cName"
								:value="item.cCode">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="li">
					<div class="left"><b>*</b>薪资:</div>
					<div class="rights">
						<input type="number" min="2070" v-model="adddata.OF_SALARY" @blur="SALARY()"
							placeholder="起始薪资" />
						<span> 至</span>
						<input type="number" :min="adddata.OF_SALARY" @blur="SALARY()" v-model="adddata.OF_SALARYE"
							placeholder="结束薪资" />
					</div>
				</div>
				<div class="li">
					<div class="left">是否面议:</div>
					<div class="right">
						<div class="right">
							<el-radio-group v-model="adddata.oF_SALARY_MY">
								<el-radio label="1">是</el-radio>
								<el-radio label="0">否</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>
				<div class="li">
					<div class="left">岗位性质:</div>
					<div class="right">
						<div class="right">
							<el-radio-group v-model="adddata.OF_POSI_NATURE">
								<el-radio label="全职">全职</el-radio>
								<el-radio label="兼职">兼职</el-radio>
							</el-radio-group>
						</div>
					</div>
				</div>
				<div class="li">
					<div class="left">招聘对象:</div>
					<div class="right">
						<el-radio-group v-model="adddata.OF_CONTAINS">
							<el-radio label="不限">不限</el-radio>
							<el-radio label="应届毕业生">应届毕业生</el-radio>
							<el-radio label="社会精英">社会精英</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="li">
					<div class="left">招聘人数:</div>
					<div class="right">
						<el-input-number v-model="adddata.OF_INVITE_NUM" :min="1" label="招聘人数"></el-input-number>
					</div>
				</div>

				<div class="li">
					<div class="left">招聘部门:</div>
					<div class="right">
						<el-select class="labelselect" v-model="sxdata[4].data" @change="SelectChange($event, 0)"
							placeholder="请选择">
							<el-option v-for="item in sxdata[4].list" :key="item.cName" :label="item.cName"
								:value="item.cCode">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="li">
					<div class="left">工作所在地:</div>
					<div class="right">
						<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange">
						</el-cascader>
					</div>
				</div>

				<div class="li">
					<div class="left">专业要求:</div>
					<div class="right">
						<input type="text" v-model="adddata.OF_MAJOR_REQ" placeholder="请输入" />
					</div>
				</div>
				<div class="li">
					<div class="left">工作经验:</div>
					<div class="right">
						<el-select class="labelselect" v-model="GZJY" placeholder="请选择">
							<el-option value="不限">不限</el-option>
							<el-option value="需要有工作经验">需要有工作经验</el-option>
						</el-select>
						<el-input-number v-model="adddata.OF_GZJY" :min="1" placeholder="请输入" v-if="GZJY!='不限'">
						</el-input-number>
					</div>
				</div>
				<div class="li">
					<div class="left">学历要求:</div>
					<div class="right">
						<el-select class="labelselect" v-model="sxdata[0].data" @change="SelectChange($event, 0)"
							placeholder="请选择">
							<el-option v-for="item in sxdata[0].list" :key="item.cName" :label="item.cName"
								:value="item.cCode">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="li">
					<div class="left">性别要求:</div>
					<div class="right">
						<el-radio-group v-model="adddata.OF_SEX">
							<el-radio label="不限">不限</el-radio>
							<el-radio label="男">男</el-radio>
							<el-radio label="女">女</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="li">
					<div class="left">年龄要求:</div>
					<div class="right">
						<input type="text" v-model="adddata.OF_AGE_REQ" placeholder="请输入" />
						<!--<el-input-number v-model="adddata.OF_AGE_REQ" :min="1" label="年龄要求"></el-input-number>-->
					</div>
				</div>
				<div class="li">
					<div class="left">有效期:</div>
					<div class="right">
						<el-date-picker v-model="adddata.OF_DEADLINE" type="date" format="yyyy-MM-dd"
							placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<!-- <div class="li">
					<div class="left">招聘状态:</div>
					<div class="right">
						<el-select class="labelselect" v-model="adddata.OF_STATUS" placeholder="请选择">
							<el-option label="招聘中" value="0"> </el-option>
							<el-option label="停止招聘" value="1"> </el-option>
						</el-select>
					</div>
				</div> -->
				<div class="ul">
					<div class="left"><b>*</b>福利待遇:</div>
					<div class="right">
						<el-checkbox-group v-model="checkList">
							<el-checkbox v-for="(item, index) in flzslist" :key="index" :label="item.id">{{ item.name }}
							</el-checkbox>
							<div style="display: inline-block; width: 50%;">
								<label
									style="width:60px;font-size: 14px;margin: 0 10px 0 30px;color:#606266;">其它福利</label>
								<input type="text" v-model="adddata.OF_FLZL_QT" placeholder="建议用逗号隔开"
									style="width: 78%;" />
							</div>
						</el-checkbox-group>
					</div>
				</div>
				<div class="ul">
					<div class="left">岗位其他要求:</div>
					<div class="right">
						<textarea v-model="adddata.OF_POSI_DESC" placeholder="请输入" cols="30" rows="7"></textarea>
					</div>
				</div>
				<div class="btn">
					<div class="preservation" @click="postAdd">保存</div>
					<div class="cancel" @click="drawer = false">取消</div>
				</div>
			</div>
		</el-drawer>
		<el-dialog title="温馨提示" :visible.sync="YWBLDialog" width="25%" center>
			<div style="width: 100%; text-align: center">
				<span v-if="ispaybycomiddata == 2">您当前未办理网络招聘业务，请前往办理?</span>
				<span v-if="ispaybycomiddata == 3">您当前办理的业务未完成支付，暂不允许该操作，请前往缴费?</span>
				<span v-if="ispaybycomiddata == 4">您当前办理的业务已过期，请前往重新办理?</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" class="footer-btn" @click="YWBLbind">确 定</el-button>
				<el-button class="footer-btn" @click="YWBLDialog = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		ispaybycomid,
		operposi,
		posilist,
		posilistall,
		flzs,
		delposi,
		refreshcompanydate,
		getywenddate,
		getoffernumbers,
		posistatus,
		ComDepSel,
	} from "@/api/company";
	import {
		GetAllHyList
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	export default {
		props: {
			postDrawer: {
				type: Boolean,
			},
			navindex: {
				type: Number,
			},
			ywstartdate: {
				type: String,
			},
		},
		data() {
			return {
				YWBLDialog: false,
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				userinfo: "",
				ispaybycomiddata: 0,
				timer: 0,
				enddate: "",
				postList: [],
				postBtnShow: false,
				drawer: false,
				direction: "rtl",
				adddata: "",
				options: regionData,
				ISClick: '',
				searchKey: '',
				POSINAME: '不限',
				GZJY: '不限',
				types: "",
				selectedOptions: ["330000", "331000", "331082"],
				checkList: [],
				flzslist: "",
				ywListId: "",
				sxdata: [{
						name: "学历",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "薪资",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "所属行业",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "部门",
						index: 0,
						list: [],
						data: ""
					},
				],
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {
			postDrawer(n, o) {
				this.drawerChange();
			},
			"$store.state.userinfo": {
				handler: function(newVal, oldVal) {
					this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
					this.timer = setInterval(this.test, 1000);
				},
			},
		},
		mounted() {
			this.flzs();
			this.posilist();
			this.ispaybycomid();
			for (const i in this.sxdata) {
				if (i != 3 && i != 2)
					this.posilistall(i);
			}
			this.GetAllHyList('');
			this.ComDepSel()
		},
		methods: {
			GetAllHyList(type) {
				var _this = this;
				var par = {
					cid: type
				}
				GetAllHyList(par).then((res) => {
					res.data.forEach((element) => {
						_this.sxdata[2].list.push({
							cName: element.com_account,
							cCode: element.auto_id,
						});
					})
				})
			},
			//表格选取
			FPtablechange(id) {
				this.ywListId = id;
			},
			//表格全选
			handleSelectionChange(data) {
				var list = "";
				for (const i in data) {
					list = list + data[i].oF_ID;

					if (parseInt(i) + 1 < data.length) {
						list = list + ",";
					}
				}
				this.ywListId = list;
			},
			ComDepSel() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				}
				ComDepSel(par).then((res) => {
					res.data.forEach((element) => {
						_this.sxdata[4].list.push({
							cName: element.dep_title,
							cCode: element.id,
						});
					})
				})
			},
			//批量操作
			FPtableAllchange(e) {
				if (!this.ywListId) {
					this.$message.warning("请选取至少一条需要操作的数据!");
					return;
				}
				var par = {
					id: this.ywListId,
					types: e,
				};
				posistatus(par).then((res) => {
					_this.$message.success("操作成功!");
					this.posilist();
				});
			},
			setsalary(a, b) {
				return utils.setsalary(a, b);
			},
			handleCommand(e) {
				this.POSINAME = e;
				if (e == "招聘中")
					this.types = 0;
				if (e == "停止招聘")
					this.types = 1;
			},
			handleCommand1(command, index, row) {
				if (command == "d") {
					this.delposi(row.oF_ID)
				}
				if (command == "t") {
					this.getywenddate(row.oF_ID, 0)
				}
				if (command == "j") {
					this.getywenddate(row.oF_ID, 1)
				}
				if (command == "s") {
					this.refreshcompanydate(row.oF_ID)
				}
				if (command == "e") {
					this.editposi(row)
				}
			},
			urlpost() {
				this.CurrentChange(1);
			},
			YWBLbind() {
				this.YWBLDialog = false;
				this.$emit("YWBLbind", {});
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.posilist();

				this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.posilist();
				this.goPost();
			},
			//岗位列表
			posilist() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					KW: _this.searchKey,
					types: _this.types,
					// showLoadType: ".resume",
				};
				posilist(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							if (element.oF_FLZL_QT) {
								element.oF_FLZL_QT_list = element.oF_FLZL_QT.split(",");
							}
							if (element.oF_DEADLINE) {
								element.oF_DEADLINE = element.oF_DEADLINE.split("T")[0];
							}
							element.switchdata = element.oF_STATUS == 0 ? true : false;
						});
						_this.total = res.data.total;
						_this.postList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},

			//岗位删除
			delposi(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					cid: id,
				};
				delposi(par).then((res) => {
					if (res.success) {
						// res.data.rows.forEach((element) => {
						//   element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						// });
						this.PageNumber = 1;
						_this.posilist();
						_this.$message.success("岗位已删除!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//新增岗位重置数据
			drawerChange() {
				var par = {
					cid: this.userinfo.coM_ID,
				};
				getoffernumbers(par).then((res) => {
					if (res.data < 1) {
						this.$message.error("当前发布岗位数量已上限！");
					} else {
						var _this = this;
						_this.adddata = {
							OF_COM_ID: "", //公司ID
							OF_ID: 0, //岗位ID(为0即为新增)
							OF_POSI_NAME: "", //岗位名称
							OF_CONTAINS: "不限", //不限、应届毕业生、社精英（传中文
							OF_POSI_TYPE: "", //职位类型，
							OF_INVITE_NUM: "1", //招聘人数，
							OF_POSI_NATURE: "全职", //岗位性质，
							OF_DEADLINE: this.utils.stringToDate(_this.ywstartdate), //有效期，
							OF_ZPBM: "", //招聘部门（中文），
							OF_PRO_CODE: "330000", //工作所在省，
							OF_CT_CODE: "331000", //工作所在市，
							OF_WORKAREA: "331082", //工作地区，
							OF_SALARY: "", //薪资起始，
							OF_SALARYE: "", //薪资结束，
							oF_SALARY_MY: "0", //是否面议
							OF_MAJOR_REQ: "不限", //所学专业，
							OF_GZJY: "不限", //工作经验，
							OF_EDU_REQ: "00", //学历(中文)，
							OF_SEX: "不限", //性别（中文，男，女，不限），
							OF_AGE_REQ: "不限", //年龄，
							OF_FL: "", //福利待遇
							OF_FLZS: 0, //福利待遇指数
							OF_FLZL_QT: "", //福利待遇，
							OF_POSI_DESC: "", //详细描述
							OF_STATUS: "0",
						};
						_this.checkList = [];
						_this.sxdata[0].data = "00";
						_this.sxdata[1].data = "";
						_this.sxdata[2].data = "";
						_this.selectedOptions = ["330000", "331000", "331082"];
						_this.drawer = true;
					}
				});
			},
			//岗位修改赋值
			editposi(item) {
				var _this = this;
				_this.adddata = {
					OF_COM_ID: item.oF_COM_ID, //公司ID
					OF_ID: item.oF_ID, //岗位ID(为0即为新增)
					OF_POSI_NAME: item.oF_POSI_NAME, //岗位名称
					OF_CONTAINS: item.oF_CONTAINS, //不限、应届毕业生、社精英（传中文
					OF_POSI_TYPE: item.oF_POSI_TYPE, //职位类型，
					OF_INVITE_NUM: item.oF_INVITE_NUM, //招聘人数，
					OF_POSI_NATURE: item.oF_POSI_NATURE, //岗位性质，
					OF_DEADLINE: item.oF_DEADLINE, //有效期，
					OF_ZPBM: item.oF_ZPBM, //招聘部门（中文），
					OF_PRO_CODE: item.oF_PRO_CODE, //工作所在省，
					OF_CT_CODE: item.oF_CT_CODE, //工作所在市，
					OF_WORKAREA: item.oF_WORKAREA, //工作地区，
					OF_SALARY: item.oF_SALARY, //薪资起始，
					OF_SALARYE: item.oF_SALARYE, //薪资结束，
					oF_SALARY_MY: item.oF_SALARY_MY, //是否面议
					OF_MAJOR_REQ: item.oF_MAJOR_REQ, //所学专业，
					OF_GZJY: item.oF_GZJY, //工作经验，
					OF_EDU_REQ: item.oF_EDU_REQ, //学历(中文)，
					OF_SEX: item.oF_SEX, //性别（中文，男，女，不限），
					OF_AGE_REQ: item.oF_AGE_REQ, //年龄，
					OF_FLZL_QT: item.oF_FLZL_QT, //福利待遇，
					OF_FL: item.oF_FL,
					OF_FLZS: item.oF_FLZS,
					OF_POSI_DESC: item.oF_POSI_DESC, //详细描述
					OF_STATUS: item.oF_STATUS,
					OF_REDATE: item.oF_REDATE,
					OF_VALID_DAYS: item.oF_VALID_DAYS,
					OF_CLICK_NUM: item.oF_CLICK_NUM,
					OF_REFRESH_DATE: item.oF_REFRESH_DATE
				};
				_this.sxdata[0].data = item.oF_EDU_REQ;

				_this.checkList = item.oF_FL ? item.oF_FL.split(",") : [];
				if (item.oF_GZJY == "不限") {
					_this.GZJY = "不限";
				} else {
					_this.GZJY = "需要有工作经验";
				}
				// if (item.oF_SALARY_MY == 1) {
				//   _this.sxdata[1].data = "面议";
				// } else {
				_this.sxdata[1].data = item.oF_SALARY + "-" + item.oF_SALARYE;
				// }
				_this.selectedOptions = [
					item.oF_PRO_CODE,
					item.oF_CT_CODE,
					item.oF_WORKAREA,
				];
				_this.sxdata[2].data = item.oF_POSI_TYPE;

				_this.sxdata[2].list.forEach((element) => {
					if (element.cName == item.oF_POSI_TYPE) {
						_this.adddata.OF_POSI_TYPE = element.cCode;
					}
				});
				_this.drawer = true;
			},
			//岗位新增
			postAdd() {
				var _this = this;
				_this.adddata.OF_COM_ID = _this.userinfo.coM_ID; //公司ID
				var adddata = _this.adddata;
				if (!adddata.OF_POSI_NAME) {
					_this.$message.warning("请输入岗位名称");
					return;
				}
				if (!adddata.OF_POSI_TYPE) {
					_this.$message.warning("请选取岗位类型");
					return;
				}
				if (adddata.oF_SALARY_MY == 0) {
					if (!adddata.OF_SALARY && !adddata.oF_SALARYE) {
						_this.$message.warning("请输入薪资");
						return;
					}
				}

				if (!adddata.OF_WORKAREA) {
					_this.$message.warning("请选取工作所在地");
					return;
				}
				if (!adddata.OF_DEADLINE) {
					_this.$message.warning("请选取有效期");
					return;
				}
				//adddata.OF_DEADLINE=this.utils.stringToDate(adddata.OF_DEADLINE);
				if (_this.checkList.length < 1) {
					_this.$message.warning("请选取至少一项福利");
					return;
				}
				if (_this.GZJY == "不限") {
					adddata.oF_GZJY = "不限"
				}
				adddata.OF_FLZS = 0;
				adddata.OF_FL = "";
				_this.checkList.forEach((element, index) => {
					_this.flzslist.forEach((item) => {
						if (element == item.id) {
							adddata.OF_FLZS = adddata.OF_FLZS + Number(item.zhishu);
						}
					});

					adddata.OF_FL = adddata.OF_FL + element;
					if (index + 1 < _this.checkList.length) {
						adddata.OF_FL = adddata.OF_FL + ",";
					}
				});
				adddata.OF_FLZS = adddata.OF_FLZS ? adddata.OF_FLZS.toFixed(2) : 0;

				operposi(this.adddata).then((res) => {
					if (res.success) {
						_this.$message.success("岗位新增成功!");
						_this.drawer = false;
						this.PageNumber = 1;
						this.navindex = 3;
						_this.posilist();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			getywenddate(id, type) {
				var _this = this;
				var par = {
					OF_STATUS: type == 0 ? "1" : "0",
					OF_ID: id,
				};
				getywenddate(par).then((res) => {
					if (res.success) {
						// res.data.rows.forEach((element) => {
						//   element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						// });
						_this.posilist();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//企业信息下拉数据
			posilistall(i) {
				var _this = this;
				var par = {
					types: _this.sxdata[i].name,
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.sxdata[i].list = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//下拉框选取
			SelectChange(e, i) {
				var _this = this;
				_this.sxdata[i].list.forEach((element) => {
					if (element.cCode == e) {
						_this.sxdata[i].data = element.cName;
					}
				});
				switch (i) {
					case 0:
						_this.adddata.OF_EDU_REQ = this.sxdata[i].data;

						break;
					case 1:
						// if (this.sxdata[i].data != "面议") {
						var data = this.sxdata[i].data.split("-");

						_this.adddata.OF_SALARY = data[0];
						_this.adddata.OF_SALARYE = data[1];
						// } else {
						//   _this.adddata.oF_SALARY_MY = 1;
						// }
						break;
					case 2:
						_this.sxdata[3].name = '';
						_this.sxdata[3].data = '';
						_this.sxdata[3].index = 0;
						_this.sxdata[3].list = [];
						_this.adddata.OF_POSI_TYPE1 = e;
						var par = {
							cid: e
						}
						GetAllHyList(par).then((res) => {
							if (res.success) {
								res.data.forEach((element) => {
									_this.sxdata[3].list.push({
										cName: element.com_account,
										cCode: element.auto_id,
									});
								})
							} else {
								_this.$message.error(res.msg);
							}
						});
						break;
					case 3:
						_this.adddata.OF_POSI_TYPE = e;
						break;
					case 4:
						_this.adddata.OF_ZPBM = e;
						break;
					default:
						break;
				}
			},
			SALARY(e) {
				if (this.adddata.OF_SALARY < 2070) {
					this.$notify({
						title: "警告",
						message: "最低工资不能低于临海市最低工资标准2070元/月。",
						type: "warning",
					});
					this.adddata.OF_SALARY = 2070;
				} else if (this.adddata.OF_SALARY && this.adddata.OF_SALARYE) {
					if (parseFloat(this.adddata.OF_SALARY) > parseFloat(this.adddata.OF_SALARYE)) {
						this.$notify({
							title: "警告",
							message: "结束薪资不能低于起始薪资！",
							type: "warning",
						});
					}
				}
			},

			refreshcompanydate(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
				};
				refreshcompanydate(par).then((res) => {
					if (res.success) {
						_this.$message.success("刷新成功!");
						_this.posilist();
						this.$emit("refreshcompany", {});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//地区选取
			handleChange(value) {
				this.adddata.OF_PRO_CODE = value[0];
				this.adddata.OF_CT_CODE = value[1];
				this.adddata.OF_WORKAREA = value[2];
			},
			//福利
			flzs() {
				var _this = this;

				flzs().then((res) => {
					if (res.success) {
						_this.flzslist = res.data;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			stringToDates(data) {
				return this.utils.stringToDate(data);
			},
			//是否可以发布岗位
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.userinfo.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
				});
			},
			enter(i) {
				this.postBtnShow = i;
			},
			leave() {
				this.postBtnShow = null;
			},
			goPost() {
				let distance =
					document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				let step = distance / 30; //每步的距离
				(function jump() {
					if (distance > 0) {
						distance -= step;
						window.scrollTo(0, distance);
						setTimeout(jump, 10);
					}
				})();
			},
			test() {
				// var t1 = "2022/01/19 17:51";
				var currTimestamp = new Date(this.userinfo.coM_UPDATE_DATE).getTime();
				var targetTimestamp = currTimestamp + 4 * 3600 * 1000;
				var t1 = new Date(targetTimestamp);

				if (new Date(t1) - new Date() <= 0) {
					clearInterval(this.timer);
					this.enddate = "";
				} else {
					var d = this.utils.cd(t1, new Date(), "d");
					var h = this.utils.cd(t1, new Date(), "h");
					var m = this.utils.cd(t1, new Date(), "m");
					var s = this.utils.cd(t1, new Date(), "s");
					var enddate = "";
					if (h > 0) {
						enddate = h + "时" + m + "分" + s + "秒";
					} else if (h == 0 && m > 0) {
						enddate = m + "分" + s + "秒";
					} else {
						enddate = s + "秒";
					}
					this.enddate = enddate;
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.post {
		width: 100%;

		.mainTitle {
			font-size: 14px;
			color: #333;

			.main {
				font-size: 28px;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
			}
		}

		.release {
			width: 90px;
			height: 30px;
			font-size: 14px;
			background: #467DF2;
			color: white;
			border-radius: 5px;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
			margin-left: 10px;
		}

		.releases {
			text-align: center;
			cursor: pointer;
			display: flex;
			align-items: center;

			i {
				font-size: 22px;
				margin-right: 5px;
			}
		}
	}


	.btn-red {
		background-color: #F50909 !important;
	}

	.btn-orange {
		background-color: #FF6000 !important;
	}

	.btn-bule {
		background-color: #467DF2 !important;
	}

	.postList {
		width: 100%;
		padding: 20px;
		background: white;
		box-sizing: border-box;
		color: #999;
		font-size: 14px;
		margin-bottom: 10px;

		.btnlist {
			.btn {
				height: 30px;
				line-height: 30px;
				color: #fff;
				padding: 0;
				width: 96px;
			}

		}


		.postul {
			display: flex;

			.postLeft {
				width: 85%;
				display: flex;

				.left {
					width: 50%;
					border-right: 1px solid #edf1f5;
				}

				.right {
					width: 50%;
					padding-left: 10px;
					position: relative;

					.time {
						margin-top: 15px;
						position: absolute;
						bottom: 0;
					}
				}
			}

			.postRight {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				width: 15%;

				.top {
					margin-bottom: 15px;

					.cszp {
						color: #fff;
						margin-left: 10px;
						border-radius: 5px;

						font-size: 13px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}

				.bottom {
					display: flex;

					.postEdit {
						// width: 105px;
						// height: 35px;
						// background: #276bf2;
						// border-radius: 4px;
						// text-align: center;
						// line-height: 35px;
						font-size: 15px;
						margin-right: 12px;
						display: flex;
						align-items: center;
						color: #276bf2;
						cursor: pointer;

						i {
							color: #276bf2;
							margin-right: 4px;
							font-size: 22px;
							font-weight: bold;
						}
					}

					.postDel {
						// width: 105px;
						// height: 35px;
						// border-radius: 4px;
						// border: 1px solid #bbb;
						// box-sizing: border-box;
						// text-align: center;
						// line-height: 35px;
						// color: #666;
						font-size: 15px;

						display: flex;
						align-items: center;
						color: #f56c6c;
						cursor: pointer;

						i {
							color: #f56c6c;
							margin-right: 4px;
							font-size: 22px;
							font-weight: bold;
						}
					}
				}
			}
		}

		.postSalary {
			margin-top: 15px;
		}

		.postName {
			color: #276bf2;
			font-size: 16px;
			margin-right: 10px;
		}

		.status {
			font-size: 14px;
			margin-right: 10px;
		}

		.date {
			color: #666;
		}

		.salary {
			color: #ff7630;
			font-size: 16px;
			margin-right: 20px;
		}

		.postFactor {
			margin-right: 10px;
		}

		.welfare {
			background: #f8f8f8;
			color: #6889aa;
			font-size: 12px;
			margin-right: 10px;
			margin-top: 5px;
		}
	}

	.postList:hover {
		box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
	}

	.postadd {
		padding: 5px 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.Tips {
			font-size: 14px;
			margin-bottom: 10px;
		}

		.TipsM {
			margin-bottom: 30px;
		}

		.li {
			width: 50%;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.left {
				width: 20%;
				font-size: 14px;
				font-weight: bold;
			}

			.right {
				width: 80%;
				font-size: 14px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 300px;
				}

				.labelselect {
					height: 40px;
					width: 300px;

					.el-input__inner {
						width: 300px;
					}
				}
			}

			.rights {
				width: 80%;
				font-size: 14px;
				display: flex;
				align-items: center;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 37%;
				}

				span {
					margin: 0 10px;
				}
			}

			b {
				color: red;
			}
		}

		.ul {
			width: 100%;
			margin-bottom: 20px;

			.left {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 10px;
			}

			.right {
				width: 100%;
				font-size: 14px;

				.el-checkbox {
					width: 80px;
					height: 40px;
					line-height: 40px;
				}

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 100%;
				}

				.labelselect {
					height: 40px;

					width: 100%;

					.el-input__inner {
						width: 100%;
					}
				}

				textarea {
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					padding: 10px 5px;
					width: 100%;
				}
			}

			b {
				color: red;
			}
		}

		.btn {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 120px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #276bf2;
				color: white;
				cursor: pointer;
			}
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			/deep/.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			/deep/.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			/deep/.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.red {
		color: #ff0000;
	}

	.green {
		color: #1f9b29;
	}

	.yellow {
		color: #ffb800;
	}

	.blue {
		color: #276bf2;
	}

	.orgin {
		color: #fb6703;
	}

	.hui {
		color: #999999;
	}

	.footer-btn {
		width: 150px;
	}
</style>
<style>
</style>
