<template>
	<div>
		<div class="Ctitle">简历管理</div>
		<div class="recommend">
			<div class="recTab flex1">
				<div :class="['recTabItem', ISClick == 2 ? 'itemClick' : '']" @click="ISClickChange(2)">
					收到的简历
				</div>
				<div :class="['recTabItem', ISClick == 3 ? 'itemClick' : '']" @click="ISClickChange(3)">
					收藏的简历
				</div>
				<div :class="['recTabItem', ISClick == 4 ? 'itemClick' : '']" @click="ISClickChange(4)">
					简历下载库
				</div>
			</div>
			<div class="search">
				<ul class="search-ul">
					<li>
						<label class="layui-text">关键字：</label>
						<input v-model="searchKey" placeholder="请输入关键字" class="search-input"></input>
					</li>
					<li class="select-div">
						<label class="layui-text">求职状态：</label>
						<el-select v-model="POSINAME" placeholder="全部">
							<el-option label="全部" value="全部"></el-option>
							<el-option label="当前在职" value="当前在职"></el-option>
							<el-option label="考虑就业机会" value="考虑就业机会"></el-option>
							<el-option label="已经离职" value="已经离职"></el-option>
							<el-option label="暂时保密" value="暂时保密"></el-option>
						</el-select>
					</li>
					<li class="clickSearch">
						<el-button type="primary" class="layui-btn layui-btn-primary" v-preventReClick
							@click="urlpost()">搜索</el-button>
					</li>
				</ul>
			</div>

			<div class="resumelist">
				<template>
					<el-table :data="PerJobList" stripe style="width: 100%" header-row-class-name="headerrowclass"
						row-class-name="rowclass">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column prop="service_Name" label="基本信息(姓名/性别/工作年限/学历/意向薪资)">
							<template slot-scope="scope">
								<div class="resume">
									<img :src="scope.row.peR_IMG_PATH ? setuserlogo(scope.row.peR_IMG_PATH) : 
								 	scope.row.peR_GENDER=='女'? require('@/assets/img/nv.png'): require('@/assets/img/male_img.png')" alt="" />
									<span @click="drawerChange(scope.row.peR_ID)"
										style="color:#101010;cursor: pointer;">
										{{scope.row.peR_NAME
										? scope.row.isDown==0&&scope.row.isJobs==0? utils.formatName(scope.row.peR_NAME):scope.row.peR_NAME
										: scope.row.peR_ACCOUNT
										? scope.row.isDown==0&&scope.row.isJobs==0? utils.formatName(scope.row.peR_ACCOUNT):scope.row.peR_ACCOUNT
										: "RC_"+scope.row.peR_ID}}</span>
									<span>|</span>
									<span>{{ scope.row.peR_GENDER ? scope.row.peR_GENDER : "男" }}</span>
									<span>|</span>
									<span>{{scope.row.peR_WORK_YEARS? scope.row.peR_WORK_YEARS + "年" : "无"}}</span>
									<span>|</span>
									<span>{{ scope.row.per_edu ? scope.row.per_edu : "高中及以下" }}</span>
									<span>|</span>
									<span>{{salary(scope.row)}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="求职状态" width="150">
							<template slot-scope="scope">
								{{scope.row.peR_PER_STATUS ? scope.row.peR_PER_STATUS: "考虑就业机会"}}
							</template>
						</el-table-column>
						<el-table-column :label="ISClick == 2?'投递时间':'活跃时间'" width="150">
							<template slot-scope="scope">
								{{ISClick == 2? scope.row.cSendDate ? utils.stringToDateTime(scope.row.cSendDate) : "":
								utils.handleDateHY(scope.row.peR_LASTLOAD) 
								 }}
							</template>
						</el-table-column>
						<el-table-column align="center" fixed="right" label="操作" width="80">
							<template slot-scope="scope">
								<div class="business-btn flex">
									<div class="business-t blue" @click="drawerChange(scope.row.peR_ID)">
										<!-- @click="talk('/resumedetail', {id: scope.row.peR_ID,uid: userinfo.coM_ID,zwid:scope.row.oF_ID, zph: '',})"-->
										查看
									</div>
									<div v-if="ISClick == 3" class="business-t blue"
										@click="cancelfavuser(scope.row.peR_ID)">删除</div>
									<div v-if="ISClick == 4" class="business-t blue"
										@click="canceldownuser(scope.row.peR_ID)">删除</div>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
			<div style="background: #fff" v-if="PerJobList.length < 1" :image-size="200"></div>
			<div class="page" v-if="PerJobList.length > 0 && total > 10">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="pageNumber"
					:page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="温馨提示" :visible.sync="YWBLDialog" width="25%" center>
			<div style="width: 100%; text-align: center">
				<span>您当前无有效的网络招聘，请前往企业中心办理?</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" class="footer-btn" @click="YWBLbind">确 定</el-button>
				<el-button class="footer-btn" @click="YWBLDialog = false">取 消</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import {
		GetPerJobByComId,
		GetComCollByComId,
		getpersonnum,
		getpersonpage,
		favuser,
		cancelfavuser,
		canceldownuser,
		getzphperjobbycomid,
		ispaybycomid,
		posilist,
	} from "@/api/company";
	import {
		Getposilist
	} from "@/api/home";

	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		CodeToText
	} from "element-china-area-data";
	export default {
		props: {
			Click: {
				type: Number,
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				YWBLDialog: false,
				userinfo: "",
				ISClick: 2,
				pageNumber: 1,
				pageSize: 10,
				total: 0,
				searchKey: "",
				searchCode: "",
				PerJobList: [],
				Posilist: [],
				ispaybycomiddata: 0,
				isfieldchage: false,
				field1: 18,
				stateyear: "",
				field2: 80,
				POSINAME: '全部',
				searcList: [{
						clcik: "",
						name: "性别",
						data: [{
								name: "不限",
								value: ""
							},
							{
								name: "男",
								value: "男"
							},
							{
								name: "女",
								value: "女"
							},
						],
					},
					{
						clcik: "",
						name: "学历",
						data: [{
							name: "不限",
							value: ""
						}],
					},
					{
						clcik: "",
						name: "活跃时间",
						data: [{
								name: "不限",
								value: ""
							},
							{
								name: "近三天",
								value: 1
							},
							{
								name: "近一周",
								value: 2
							},
							{
								name: "近一个月",
								value: 3
							},
							{
								name: "近三个月",
								value: 4
							},
							// {
							// 	name: "近半年",
							// 	value: 5
							// },
						],
					},
				],
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			//this.Getposilists();
			this.posilist()
		},
		watch: {
			field1(value, o) {
				if (value < 18) {
					this.field1 = 18;
				} else if (value > 80) {
					this.field1 = 80;
				} else if (value > this.field2) {
					this.field1 = this.field2;
				} else {
					this.field1 = value;
				}
			},
			field2(value, o) {
				if (value < 18) {
					this.field2 = 18;
				} else if (value > 80) {
					this.field2 = 80;
				} else if (value < this.field1) {
					this.field2 = this.field1;
				} else {
					this.field2 = value;
				}
			},
		},
		mounted() {
			this.urlpost();
		},
		methods: {
			CodeToText(value) {
				return CodeToText[value];
			},
			drawerChange(id) {
				this.$parent.isCall = this.ISClick == 3 ? 0 : 1;
				this.$parent.uid = id;
				this.$parent.isRshow = !this.$parent.isRshow;
			},
			posilist() {
				var _this = this;
				posilist({
					id: this.userinfo.coM_ID
				}).then((res) => {
					_this.Posilist = res.data.rows;
				});
			},
			YWBLbind() {
				this.YWBLDialog = false;
				if (this.ispaybycomiddata > 1) {
					this.$emit("YWBLbind", {});
				}
			},
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.userinfo.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
					if (_this.ispaybycomiddata != 0) {
						this.ISClick = 2;
					}
					this.urlpost();
				});
			},
			//提交大类
			ISClickChange(i) {
				this.ISClick = i;
				this.PageSize = 10;
				this.PageNumber = 1;
				this.PerJobList = [];
				this.urlpost();
			},

			handleCommand(e) {
				this.POSINAME = e;
				this.urlpost();
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.urlpost();

				// this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.urlpost();
				// this.goPost();
			},
			SelectTap(i, j) {
				this.searcList[i].clcik = this.searcList[i].data[j].value;
				this.PageNumber = 1;
				this.getpersonpage();
			},
			yearselect() {
				var time = new Date();
				let year = time.getFullYear();
				this.stateyear = year - this.field1;

				this.endyear = year - this.field2;
				this.PageNumber = 1;
				this.getpersonpage();
			},

			//人才简历列表
			getpersonpage() {
				var _this = this;
				var par = {
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					id: this.userinfo.coM_ID,
					searchKey: _this.searchKey,
					showLoadType: ".resumelist",
				};
				getpersonpage(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才投递简历列表
			GetPerJobByComId() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID, //4732,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					code: _this.searchCode > 0 ? _this.searchCode : "",
					searchKey: _this.searchKey,
					showLoadType: ".resumelist",
				};
				GetPerJobByComId(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才简历收藏
			GetComCollByComId() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					code: _this.searchCode > 0 ? _this.searchCode : "",
					searchKey: _this.searchKey,
					field1: _this.ISClick == 3 ? 0 : 1,
					showLoadType: ".resumelist",
				};
				GetComCollByComId(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//招聘会人才投递简历列表
			getzphperjobbycomid() {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					code: _this.searchCode > 0 ? _this.searchCode : "",
					searchKey: _this.searchKey,
					showLoadType: ".resumelist",
				};
				getzphperjobbycomid(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: this.userinfo.coM_ID,
					uid: id,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.urlpost();

						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消下载简历
			canceldownuser(id) {
				var _this = this;
				handleCofirm('确认删除该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: id,
					};
					canceldownuser(par).then((res) => {
						if (res.success) {
							this.urlpost();
							_this.$message.success("已删除下载!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.userinfo.coM_ID,
						uid: id,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.urlpost();

							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			Getposilists() {
				var _this = this;
				Getposilist({
					types: "学历"
				}).then((res) => {
					if (res.success) {
						_this.educationlist = res.data.rows;
						res.data.rows.forEach((element) => {
							if (element.cName != "不限") {
								this.searcList[1].data.push({
									name: element.cName,
									value: element.cCode,
								});
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			urlpost() {
				if (this.ISClick == 1 && this.ispaybycomiddata == 0) {
					this.getpersonpage();
				} else if (this.ISClick == 2) {
					this.GetPerJobByComId();
				} else if (this.ISClick == 3) {
					this.GetComCollByComId();
				} else if (this.ISClick == 4) {
					this.GetComCollByComId();
				}
			},
			talk(url, data) {
				this.resolvewin(url, data, 2);
			},
			salary(salarys) {
				var data = [];
				var a = salarys.salary;
				var b = salarys.salarye;
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
				if (data.indexOf("-") == -1) {
					return "面议";
				} else {
					return data;
				}
			},
			setuserlogo(url) {
				var data = url.indexOf("http");

				return isnull(url) ?
					require("@/assets/img/logos.png") :
					data < 0 ?
					this.defimgurl + url :
					url;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.Ctitle {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
		background: white;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.business-t {
		cursor: pointer;
	}

	.recommend {
		width: 100%;

		.recTab {
			width: 100%;
			height: 60px;
			background: white;
			padding: 0 25px;
			box-sizing: border-box;
			color: #333;
			border-bottom: 1px solid #eee;

			.recTabItem {
				height: 100%;
				line-height: 60px;
				margin-right: 50px;
				color: #898989;
			}

			.itemClick {
				box-sizing: border-box;
				color: #101010;
				font-size: 16px;
			}

			.recTabItem:hover {
				cursor: pointer;
			}
		}

		.resumelist {
			color: #898989;

			.resume {
				display: flex;
				line-height: 36px;

				img {
					width: 36px;
					height: 36px;
				}

				span {
					line-height: 36px;
					margin-left: 10px;
				}
			}
		}

		.talk,
		.collect {
			cursor: pointer;
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			/deep/.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			/deep/.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			/deep/.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	.han {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
